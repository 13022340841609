var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "relevanceOrgAuditForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              submitText: "保存",
              submitUrl: _vm.submitUrl,
              footerShow: _vm.footerShow,
            },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "用户审核信息" } },
                [
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "姓名",
                      text: _vm.detailItem.userName,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "性别",
                      text: _vm.sexMap[_vm.detailItem.sex],
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "手机号",
                      text: _vm.detailItem.mobileNum,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "项目住址",
                      text: _vm.detailItem.communityAddress,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "申请时间",
                      text: _vm.detailItem.intime,
                    },
                  }),
                  _vm.detailItem.approveStatus === 0
                    ? _c(
                        "el-form-item",
                        {
                          staticClass: "el-form-wrapper",
                          attrs: { label: "审核状态" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "choose-status" },
                            [
                              _c("v-select", {
                                attrs: { options: _vm.statusOps },
                                model: {
                                  value: _vm.form.approveStatus,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "approveStatus", $$v)
                                  },
                                  expression: "form.approveStatus",
                                },
                              }),
                              _c("span", { staticClass: "tip-text" }, [
                                _vm._v(
                                  "\n              注：请确认核对用户信息，再进行操作。\n            "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.form.approveStatus === 2
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "拒绝原因",
                            prop: "approveComments",
                            rules: [
                              {
                                required: true,
                                message: "请输入拒绝原因",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-textarea", {
                            attrs: { placeholder: "请输入拒绝原因" },
                            model: {
                              value: _vm.form.approveComments,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "approveComments", $$v)
                              },
                              expression: "form.approveComments",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.detailItem.approveStatus !== 0
                    ? _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "审核状态",
                          text: _vm.statusMap[_vm.detailItem.approveStatus],
                        },
                      })
                    : _vm._e(),
                  _vm.detailItem.approveStatus !== 0
                    ? _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "操作说明",
                          text: _vm.detailItem.approveComments,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }