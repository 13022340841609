<!--
 * @Description: 用户审核--关联组织审核-表单页面
 * @Author: 小广
 * @Date: 2019-05-29 18:33:57
 * @LastEditors: 小广
 * @LastEditTime: 2019-07-16 10:06:16
 -->
<template>
  <div class="relevanceOrgAuditForm-wrapper">
    <form-panel
      ref="formPanel"
      :form="form"
      submitText="保存"
      v-bind="submitConfig"
      :submitUrl="submitUrl"
      :footerShow="footerShow"
    >
    <col2-detail>
      <col2-block title="用户审核信息">
        <col2-item :span="24" label="姓名" :text="detailItem.userName" />
        <col2-item :span="24" label="性别" :text="sexMap[detailItem.sex]" />
        <col2-item :span="24" label="手机号" :text="detailItem.mobileNum" />
        <col2-item
          :span="24"
          label="项目住址"
          :text="detailItem.communityAddress"
        />
        <col2-item :span="24" label="申请时间" :text="detailItem.intime" />
        <el-form-item
            class="el-form-wrapper"
            v-if="detailItem.approveStatus === 0"
            label="审核状态" >
            <div class="choose-status">
              <v-select
                v-model="form.approveStatus"
                :options="statusOps"
              ></v-select>
              <span class="tip-text">
                注：请确认核对用户信息，再进行操作。
              </span>
            </div>
        </el-form-item>
        <el-form-item
          v-if="form.approveStatus === 2"
          label="拒绝原因"
          prop="approveComments"
          :rules="[
            { required: true, message: '请输入拒绝原因', trigger: 'blur' }
          ]"
        >
          <v-textarea
            v-model="form.approveComments"
            placeholder="请输入拒绝原因"
          ></v-textarea>
        </el-form-item>
        <col2-item
          v-if="detailItem.approveStatus !== 0"
          :span="24"
          label="审核状态"
          :text="statusMap[detailItem.approveStatus]"
        />
        <col2-item
          v-if="detailItem.approveStatus !== 0"
          :span="24"
          label="操作说明"
          :text="detailItem.approveComments"
        />
      </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { getDetailURL, batchURL } from './api'
import { sexMap, statusMap, statusOps } from './map'
import { Col2Block, Col2Item, Col2Detail } from 'components/bussiness'

export default {
  name: '',
  /// 引用的组件处理
  components: {
    Col2Block,
    Col2Item,
    Col2Detail
  },

  created () {
    // 设置title
    this.$setBreadcrumb('用户信息审核详情')
  },
  // 加载详情数据
  mounted () {
    this.getDetailData()
  },

  /// 必有方法
  data () {
    return {
      submitUrl: batchURL,
      submitConfig: {
        submitMethod: 'post'
      },
      detailItem: {},
      //
      form: {
        approveStatus: 1,
        approveComments: ''
      },
      statusOps: statusOps,
      statusMap: statusMap,
      sexMap: { 0: '未知', ...sexMap },
      footerShow: false
    }
  },

  methods: {
    /// 获取详情数据
    getDetailData () {
      let _this = this
      let requestUrl = `${getDetailURL}/${this.$route.query.id}`
      this.$axios
        .get(requestUrl, {})
        .then(res => {
          if (res.status === 100) {
            _this.detailItem = res.data || {}
            _this.footerShow = _this.detailItem.approveStatus === 0
            if (_this.footerShow) {
              _this.updatForm()
            }
          }
        })
    },

    // 更新form数据
    updatForm () {
      let dataObject = `${this.detailItem.userName}/${this.detailItem.mobileNum}`
      let form = {
        userList: [this.detailItem.id],
        approveComments: '',
        approveStatus: 1,
        dataObject: dataObject
      }

      this.form = Object.assign({}, form)
    }
  }
}
</script>

<style scope lang="scss">
.relevanceOrgAuditForm-wrapper {

  .el-form-wrapper {
      width: 100%;

      .choose-status {
        display: flex;

        .tip-text {
          margin-left: 15px;
          font-style: oblique;
        }
      }
    }
}
</style>
